/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
.login-alert {
  --backdrop-opacity: 100%;
}

ion-modal.po-goods-in-qty-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.po-goods-in-qty-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal.po-goods-in-qty-modal ion-icon {
  margin-right: 6px;
  width: 48px;
  height: 48px;
  padding: 4px 0;
  color: #aaaaaa;
}

ion-modal.po-goods-in-qty-modal .wrapper {
  margin-bottom: 10px;
}

ion-modal.po-goods-in-qty-modal::part(backdrop) {
  background: rgb(0, 0, 0);
  opacity: 1;
  --backdrop-opacity: var(--ion-backdrop-opacity, 1);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center;
}